import React from "react";
import { formatArrivalDate, formatDateIfNeeded } from "../helpers/helpers";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Intervals = ({ allData }) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [sortHour, setSortHour] = React.useState("08_00");

  // Generate the array of hours with 30-minute intervals
  const hours = [];
  for (let hour = 8; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      hours.push(
        `${hour.toString().padStart(2, "0")}_${minute
          .toString()
          .padStart(2, "0")}`
      );
    }
  }

  // Sort the attacks by arrival time

  const filteredAttacks = allData.filter((attack) => {
    let formattedArrival = formatDateIfNeeded(attack.arrival);
    return (
      formattedArrival.getDate() === selectedDate.getDate() &&
      formattedArrival.getMonth() === selectedDate.getMonth()
    );
  });

  const sortedAttacks = filteredAttacks.sort(
    (a, b) => formatDateIfNeeded(a.arrival) - formatDateIfNeeded(b.arrival)
  );

  // Assign the attacks to fields based on their target value
  const fields = {};
  sortedAttacks.forEach((attack) => {
    const { target } = attack;
    if (!fields[target]) {
      fields[target] = [];
    }
    fields[target].push(attack);
  });

  // Group the attacks into 30-minute intervals
  const intervals = {};
  let currentInterval = [];
  let currentHour = 8;
  let currentMinute = 0;

  sortedAttacks.forEach((attack) => {
    const { arrival } = attack;
    const formattedArrival = formatDateIfNeeded(arrival);
    const hour = formattedArrival.getHours();
    const minute = formattedArrival.getMinutes();

    // Check if the attack falls within the current interval
    if (
      hour > currentHour ||
      (hour === currentHour && minute >= currentMinute + 30)
    ) {
      const intervalKey = `${currentHour
        .toString()
        .padStart(2, "0")}_${currentMinute.toString().padStart(2, "0")}`;
      intervals[intervalKey] = currentInterval;
      currentInterval = [];
      currentHour = hour;
      currentMinute = minute;
    }

    currentInterval.push(attack);
  });

  // Create an object for each field with 30-minute intervals
  const fieldIntervals = {};
  Object.keys(fields).forEach((field) => {
    const fieldAttacks = fields[field];
    const fieldInterval = {};
    let currentFieldHour = 8;
    let currentFieldMinute = 0;

    fieldAttacks.forEach((attack) => {
      const { arrival } = attack;
      const formattedArrival = formatDateIfNeeded(arrival);
      const hour = formattedArrival.getHours();
      const minute = formattedArrival.getMinutes();

      // Check if the attack falls within the current interval for the field
      if (
        hour > currentFieldHour ||
        (hour === currentFieldHour && minute >= currentFieldMinute + 30)
      ) {
        const intervalKey = `${currentFieldHour
          .toString()
          .padStart(2, "0")}_${currentFieldMinute.toString().padStart(2, "0")}`;
        fieldInterval[intervalKey] = currentInterval;
        currentInterval = [];
        currentFieldHour = hour;
        currentFieldMinute = minute;

        // Round the minute to the lower end (00 or 30)
        currentFieldMinute = Math.floor(currentFieldMinute / 30) * 30;
      }

      currentInterval.push(attack);
    });

    fieldIntervals[field] = fieldInterval;
  });

  const sortedFieldIntervals = Object.entries(fieldIntervals).sort(
    ([aKey, aInterval], [bKey, bInterval]) => {
      const aLength = aInterval[sortHour || "08_00"]?.length || 0;
      const bLength = bInterval[sortHour || "08_00"]?.length || 0;
      return bLength - aLength;
    }
  );
  // Render the intervals
  const intervalSums = hours.map((hour) => {
    const sum = sortedFieldIntervals.reduce(
      (total, [intervalKey, interval]) => {
        return total + (interval[hour]?.length || 0);
      },
      0
    );
    return sum;
  });

  return (
    <div>
      Wybierz datę
      <div className="text-black m-2">
        <ReactDatePicker
          selected={selectedDate}
          onChange={(e) => setSelectedDate(e)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            {hours.map((hour, index) => (
              <th key={index} onClick={() => setSortHour(hour)}>
                {hour.replace("_", ":")}
                <br />
                {intervalSums[index]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedFieldIntervals.map(([intervalKey, interval], index) => (
            <tr key={index}>
              <td>{intervalKey}</td>
              {hours.map((hour, index) => (
                <td key={index}>{interval[hour]?.length}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Intervals;
