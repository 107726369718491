import React from "react";
import { AgGridReact } from "ag-grid-react";
import { formatDateIfNeeded } from "../helpers/helpers";

const RawAttacks = ({ richData }) => {
  // console.log(richData);
  const columnDefs = [
    { headerName: "Obrońca", field: "targetPlayer", filter: true },
    { headerName: "Wioska", field: "target", filter: true },
    { headerName: "Atakujcy", field: "attacker", filter: true },
    { headerName: "Pochodzenie", field: "origin", filter: true },
    {
      headerName: "Wysłanie",
      field: "sent",
      valueFormatter: (params) => new Date(params.value).toLocaleString("pl"),
      filter: true,
    },
    {
      headerName: "Dotarcie",
      field: "arrival",
      valueFormatter: (params) =>
        formatDateIfNeeded(params.value).toLocaleString("pl"),
      filter: true,
    },
    {
      headerName: "Distinct",
      field: "distinctPercent",
      valueFormatter: (params) => `${params.value}%`,
      filter: true,
    },
    {
      headerName: "Closes",
      field: "isLastSent",
      valueFormatter: (params) => (params.value ? "Tak" : "Nie"),
      filter: true,
    },
    {
      headerName: "Gruby",
      field: "isNoble",
      valueFormatter: (params) => (params.value ? "Tak" : "Nie"),
      filter: true,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: "900px", width: "100%" }}>
      <AgGridReact
        columnDefs={columnDefs}
        enableCellTextSelection={true}
        rowData={richData}
      ></AgGridReact>
    </div>
  );
};

export default RawAttacks;
