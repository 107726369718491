import React, { useEffect } from "react";
import BasicMode from "./BasicMode";
import App from "./App";

const Validator = () => {
  const [hasAccess, setHasAccess] = React.useState(false);
  const [loginError, setLoginError] = React.useState("");

  useEffect(() => {
    localStorage.getItem("token") && setHasAccess(true);
  });

  const handleLogin = (username, password) => {
    fetch("https://api.trackitup.pl:3000/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((data) => {
        if (data.status === 200) {
          setHasAccess(true);
          localStorage.setItem("token", username);
        } else {
          setLoginError(data.status);
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src="./logo.png" alt="Logo" className="h-20 w-40 self-center" />
        <h1 className="text-2xl">
          Super turbo underowy analizator{" "}
          <span className="text-xs text-yellow-500">by Snaki</span>
        </h1>
        {hasAccess ? (
          <App />
        ) : (
          <BasicMode handleLogin={handleLogin} loginError={loginError} />
        )}
      </header>
    </div>
  );
};

export default Validator;
