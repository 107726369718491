import React from "react";
import AddIncomings from "./Dialogs/AddIncomings";
import AddRaports from "./Dialogs/AddRaports";

function BasicMode({ handleLogin, loginError }) {
  const [addingOpen, setAddingOpen] = React.useState(false);
  const [raportOpen, setRaportOpen] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [login, setLogin] = React.useState("");
  const [pass, setPass] = React.useState("");
  const currentHour = new Date().getHours();
  console.log("current hour", currentHour);

  const targetDate = new Date();
  targetDate.setHours(21, 0, 0, 0);
  console.log("target date", targetDate);
  return (
    <>
      <button
        className="bg-white text-black p-2 m-2 w-60 rounded-xl self-center mt-10"
        onClick={() => {
          setAddingOpen(true);
        }}
      >
        Dodaj zrzut
      </button>
      <AddIncomings
        onClose={(e) => setAddingOpen(false)}
        isOpen={addingOpen}
        secure
      />
      <button
        className="bg-white text-black p-2 m-2 w-60 rounded-xl self-center mt-1"
        onClick={() => {
          setRaportOpen(true);
        }}
      >
        Dodaj raporty
      </button>
      <AddRaports onClose={(e) => setRaportOpen(false)} isOpen={raportOpen} />
      {openLogin && (
        <div className="self-center">
          Zaloguj się aby uzyskać dostęp do analizy
          <div className="flex w-60 self-center justify-between mt-2">
            Login:
            <input
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              className="w-40 text-black p-1 rounded-md"
            />
          </div>
          <div className="flex w-60 self-center justify-between mt-2">
            Hasło:
            <input
              type={"password"}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              className="w-40 text-black p-1 rounded-md"
            />
          </div>
          {loginError && <div className="text-red-500">Błąd logowania</div>}
          <button
            className="bg-white text-black p-1 m-2 w-40 rounded-xl self-center"
            onClick={() => handleLogin(login, pass)}
          >
            Zaloguj
          </button>
        </div>
      )}
      <div>
        <button
          className="bg-gray-700 text-black p-1 m-2 w-40 rounded-xl self-center absolute right-0 bottom-0"
          onClick={() => setOpenLogin(true)}
        >
          Zaloguj
        </button>
      </div>
    </>
  );
}

export default BasicMode;
