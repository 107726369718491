import { useEffect, useState } from "react";
import "./App.css";
import Intervals from "./Views/Intervals";
import AddIncomings from "./Dialogs/AddIncomings";
import { formatArrivalDate } from "./helpers/helpers";
import RawAttacks from "./Views/RawAttacks";
import Summary from "./Views/Summary";
import PlayerOverview from "./Views/PlayerOverview";
import AttackerOverview from "./Views/AttackerOverview";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReactDatePicker from "react-datepicker";
import Raports from "./Views/Raports";
import AddRaports from "./Dialogs/AddRaports";
import CodeGen from "./Dialogs/CodeGen";
import Villages from "./Views/Villages";
import SummaryExtended from "./Views/SummaryExtended";

function App() {
  const [rawData, setRawData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [expandedAttacker, setExpandedAttacker] = useState(null);
  const [addingOpen, setAddingOpen] = useState(false);
  const [codeOpen, setCodeOpen] = useState(false);
  const [raportOpen, setRaportOpen] = useState(false);
  const [enrichedData, setEnrichedData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const [flatEnrichedData, setFlatEnrichedData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(undefined);

  const fetchData = () => {
    fetch("https://api.trackitup.pl:3000/api/incomings", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        // Handle the data from the API response
        const dataToMerge = data.filter((item) =>
          item.name.includes("_$_part")
        );
        const baseData = data.filter((item) => !item.name.includes("_$_part"));
        const mergedData = baseData.map((item) => {
          const partData = dataToMerge.filter((part) => {
            const index = part.name.indexOf("_$_part");
            const name = part.name.slice(0, index);
            return name === item.name;
          });
          const mergedIncomings = partData.reduce((acc, part) => {
            return acc.concat(part.data);
          }, item.data);
          return {
            ...item,
            data: mergedIncomings,
          };
        });
        setRawData(mergedData);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedDate === undefined) {
      return;
    }
    if (selectedDate === null) {
      fetchData();
      return;
    }
    // console.log(rawData);
    const filteredData = rawData.map((item) => {
      return {
        ...item,
        data: item.data.filter((attack) => {
          let arrival;
          if (attack.arrival.split) {
            arrival = new Date(formatArrivalDate(attack.arrival));
          } else {
            arrival = new Date(attack.arrival);
          }

          return (
            arrival.getDate() === selectedDate.getDate() &&
            arrival.getMonth() === selectedDate.getMonth()
          );
        }),
      };
    });

    // console.log(filteredData);
    setRawData(filteredData);
  }, [selectedDate]);

  useEffect(() => {
    // console.log(rawData);
    const allData = rawData.reduce((acc, attack) => {
      return acc.concat(attack.data);
    }, []);

    setFlatData(allData);

    const newData = {};
    rawData.forEach((item) => {
      item.data.forEach((data) => {
        const attacker = data.attacker;
        if (newData[attacker] instanceof Array) {
          newData[attacker].push({
            ...data,
            targetPlayer: item.name,
          });
        } else {
          newData[attacker] = [
            {
              ...data,
              targetPlayer: item.name,
            },
          ];
        }
      });
    });

    setFormattedData(newData);
    setPlayerList(Object.keys(newData));
  }, [rawData]);

  useEffect(() => {
    const richData = rawData.map((playerData) => {
      const richIncomings = playerData.data.map((attack) => {
        const matches = flatData
          .filter((item) => item.origin === attack.origin)
          .sort((a, b) => a.sent - b.sent);
        const distinctPercent = Math.round((1 / matches.length) * 100);
        const isLastSent =
          matches[matches.length - 1].arrival === attack.arrival;
        return {
          ...attack,
          distinctPercent,
          isLastSent: isLastSent,
          targetPlayer: playerData.name,
          attacker: attack.attacker,
        };
      });
      return { player: playerData.name, data: richIncomings };
    });

    const allRichData = richData.reduce((acc, attack) => {
      return acc.concat(attack.data);
    }, []);

    setFlatEnrichedData(allRichData);
    setEnrichedData(richData);
  }, [formattedData]);

  const targetDate = new Date();
  targetDate.setHours(21, 0, 0, 0);
  return (
    <>
      <div>
        <button
          className="bg-white text-black p-2 m-2 w-60 rounded-xl self-center"
          onClick={() => {
            setAddingOpen(true);
          }}
        >
          Dodaj zrzut
        </button>
        <button
          className="bg-white text-black p-2 m-2 w-60 rounded-xl self-center mt-1"
          onClick={() => {
            setRaportOpen(true);
          }}
        >
          Dodaj raporty
        </button>
        <button
          className="bg-white text-black p-2 m-2 w-60 rounded-xl self-center mt-1"
          onClick={() => {
            setCodeOpen(true);
          }}
        >
          Generuj kod
        </button>
      </div>
      <AddIncomings
        onClose={(e) => setAddingOpen(false)}
        isOpen={addingOpen}
        // onAdd={(returnValue) => {
        //   setRawData(returnValue);
        // }}
        secure={false}
      />

      <AddRaports onClose={(e) => setRaportOpen(false)} isOpen={raportOpen} />
      <CodeGen isOpen={codeOpen} onClose={() => setCodeOpen(false)} />
      <div>Łącznie ataków: {flatData.length}</div>
      <div>FIltr Globalny: </div>
      <div className="text-black m-2">
        <ReactDatePicker
          selected={selectedDate}
          onChange={(e) => setSelectedDate(e)}
          disabled={selectedDate}
        />
        <span onClick={() => setSelectedDate(null)} className="text-white ml-2">
          Resetuj
        </span>
      </div>
      <Tabs>
        <TabList>
          <Tab>Obrońcy</Tab>
          <Tab>Ataki</Tab>
          <Tab>Grafik</Tab>
          <Tab>Pełna Lista ataków</Tab>
          <Tab>Podsumowanie Szybkie</Tab>
          <Tab>Podsumowanie Pełne</Tab>
          <Tab>Raporty</Tab>
          <Tab>Wioski</Tab>
          <Tab>Mapa</Tab>
        </TabList>

        <TabPanel>
          <PlayerOverview rawData={rawData} />
        </TabPanel>
        <TabPanel>
          <AttackerOverview formattedData={formattedData} />
        </TabPanel>
        <TabPanel>
          <Intervals allData={flatData} />
        </TabPanel>
        <TabPanel>
          <RawAttacks richData={flatEnrichedData} />
        </TabPanel>
        <TabPanel>
          <Summary richData={flatEnrichedData} />
        </TabPanel>
        <TabPanel>
          <SummaryExtended richData={flatEnrichedData} />
        </TabPanel>
        <TabPanel>
          <Raports />
        </TabPanel>
        <TabPanel>
          <Villages />
        </TabPanel>
        <TabPanel>No kiedys będzie nie</TabPanel>
      </Tabs>
      <div>
        <button
          className="bg-gray-700 text-black p-1 m-2 w-40 rounded-xl self-center fixed right-0 bottom-0"
          onClick={() => {
            localStorage.removeItem("token");
            window.location.reload();
          }}
        >
          Wyloguj
        </button>
      </div>
      {/* <Closes richData={flatEnrichedData} /> */}
    </>
  );
}

export default App;
