import React, { useEffect, useState } from "react";

function AddRaports({ onClose, isOpen }) {
  const [raports, setRaports] = useState("");

  const handleSend = () => {
    const extractReportIds = (mock) => {
      const regex = /\[report\](.*?)\[\/report\]/g;
      const matches = mock.match(regex);
      if (matches) {
        return matches.map((match) =>
          match.replace(/\[report\]|\[\/report\]/g, "")
        );
      }
      return [];
    };

    const reportIds = extractReportIds(raports);
    reportIds.forEach((reportId) => {
      fetch("https://api.trackitup.pl:3000/api/reports", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ reportId: reportId }),
      })
        .then((response) => response.json())
        .then((data) => {
          // handle the response data
        })
        .catch((error) => {
          // handle the error
        });
    });

    setRaports("");
    onClose();
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-10">
          <div
            className="opacity-40 bg-black w-full h-full absolute"
            onClick={() => {
              setRaports("");
              onClose();
            }}
          />
          <div className="border-solid border-black bg-gray-300 z-10 self-center text-black p-5">
            <div className="font-bold">Dodaj Raporty</div>
            <div className="mt-2 flex flex-col">
              Wklej
              <textarea
                value={raports}
                onChange={(e) => setRaports(e.target.value)}
                rows="5"
                cols="70"
                className=" w-full rounded-md resize"
                placeholder="Przesłane raporty:
- [report]2754c627c87225bfd0924e2b5d3d91b7[/report]
- [report]e56455f7b7108ddf7a0125ea3919a8e3[/report]
- [report]a98b9c3e50aa87d8b32a6e7d851b7006[/report]"
              ></textarea>
              <button
                onClick={handleSend}
                disabled={!raports}
                className={
                  "bg-white text-black p-2 m-2 rounded-xl" +
                  (!raports ? " bg-gray-200 text-gray-300" : "")
                }
              >
                Wyślij
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddRaports;
