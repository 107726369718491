import React, { useEffect } from "react";
import PlayerDetails from "../Dialogs/PlayerDetails";
import Loader from "../Components/Loader";
import moment from "moment";

const PlayerOverview = ({ rawData }) => {
  // Implement your component logic here
  const [playerData, setPlayerData] = React.useState([]);
  const [details, setDetails] = React.useState({});
  const [openPlayerDetails, setOpenPlayerDetails] = React.useState("");

  useEffect(() => {
    fetch("https://api.trackitup.pl:3000/api/playerInfo")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPlayerData(data);
      });
  }, []);

  useEffect(() => {
    const newDetails = {};
    const uploadedPlayers = rawData.map((item) => item.name);
    playerData.forEach((player) => {
      player.villages.forEach((village, i) => {
        if (i === 0) {
          newDetails[player.player_name] = {
            incoming: 0,
            player_name: player.player_name,
            uploaded: uploadedPlayers.includes(player.player_name),
          };
        }
        newDetails[player.player_name].incoming += village.incoming;
      });
    });
    setDetails((prevDetails) => newDetails);
  }, [playerData]);

  return (
    <div className="flex flex-col items-center">
      Zrzuty w bazie
      {rawData.length > 0 ? (
        rawData
          .sort((a, b) => (a.data.length > b.data.length ? -1 : 1))
          .map((item) => {
            const nobleCount = item.data.filter((i) => i.isNoble);
            return (
              <div
                key={item.id}
                className="flex w-1/4  justify-between"
                onClick={() => setOpenPlayerDetails(item.name)}
              >
                <p>{item.name}</p>
                <div>
                  <div className="flex">
                    {item.data.length}
                    <p className="text-yellow-500">
                      ({nobleCount.length})
                    </p>{" "}
                    <p className="text-blue-500">
                      ({moment(item.date).format("DD.MM.YYYY, HH:mm:ss")})
                    </p>
                  </div>
                </div>
              </div>
            );
          })
      ) : (
        <Loader />
      )}
      <br />
      Ataki bez zrzutów
      {Object.keys(details).length !== 0 ? (
        Object.values(details)
          .sort((a, b) => b.incoming - a.incoming)
          .map((item) => {
            return (
              !item.uploaded &&
              item.incoming > 0 && (
                <div
                  key={item.id}
                  className="flex w-1/4  justify-between"
                  onClick={() => setOpenPlayerDetails(item.player_name)}
                >
                  <p>{item.player_name}</p>
                  <div>
                    <div className={`flex text-red-500`}>{item.incoming}</div>
                  </div>
                </div>
              )
            );
          })
      ) : (
        <Loader />
      )}
      {openPlayerDetails && (
        <PlayerDetails
          onClose={() => setOpenPlayerDetails(null)}
          data={playerData.find(
            (item) => item?.player_name === openPlayerDetails
          )}
        />
      )}
    </div>
  );
};

export default PlayerOverview;
