import React, { useEffect, useState } from "react";
import { MD5 } from "crypto-js";
const userScript = `// ==UserScript==
// @name     GET message autocomplete
// @version  2.0
// @match    *://*.plemiona.pl/game.php*screen=mail*mode=new*
// ==/UserScript==
// By Rafsaf

const hash = window.location.hash.substr(1);
const result = hash.split('&').reduce(function (res, item) {
var parts = item.split('=');
res[parts[0]] = parts[1];
return res;
}, {});
if ("to" in result) {
document.getElementById('to').value = decodeURIComponent(result.to);
}
if ("subject" in result) {
document.getElementsByName('subject')[0].value = decodeURIComponent(result.subject);
}
if ("message" in result) {
document.getElementById('message').value = decodeURIComponent(result.message);
}`;

export const generateCode = (playerName) => {
  const hashedName = MD5(playerName).toString().toUpperCase().slice(0, 6);
  return hashedName;
};

function CodeGen({ onClose, isOpen }) {
  const [playerName, setPlayerName] = useState("");
  const [code, setCode] = useState("");
  const [mailingMode, setMailingMode] = useState(false);
  const [players, setPlayers] = useState("");
  const [template, setTemplate] = useState("");
  const [subject, setSubject] = useState("");

  const generate = () => {
    setCode(generateCode(playerName));
  };

  const getTemplate = (template, playerName) => {
    return template
      .replace(/\[name\]/g, playerName)
      .replace(/\[code\]/g, generateCode(playerName));
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-10">
          <div
            className="opacity-40 bg-black w-full h-full absolute"
            onClick={() => {
              setMailingMode(false);
              onClose();
            }}
          />
          <div className="border-solid border-black bg-gray-300 z-10 self-center text-black p-5">
            {mailingMode ? (
              <div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(userScript);
                  }}
                  className="bg-white text-black p-2 m-2 rounded-xl"
                >
                  Skopiuj skrypt do małpki (legalny)
                </div>
                Lista graczy oddzielona średnikami
                <textarea
                  value={players}
                  onChange={(e) => setPlayers(e.target.value)}
                  rows="3"
                  cols="33"
                  className=" w-full rounded-md resize"
                />
                Temat wiadomości
                <input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className=" w-full rounded-md resize"
                />
                Szablon wiadomości
                <p className="text-gray-400">
                  Użyj [name] aby wpisać nazwę gracza i [code] aby wypełnić
                  kodem
                </p>
                <textarea
                  value={template}
                  onChange={(e) => setTemplate(e.target.value)}
                  rows="5"
                  cols="33"
                  className=" w-full rounded-md resize"
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
                <br />
                Linki do wysłania wiadomości: <br />
                <div className="h-80 overflow-auto">
                  {players.split(";").map((player) => {
                    return (
                      <div className="text-blue-700 underline">
                        <a
                          href={`https://pl203.plemiona.pl/game.php?screen=mail&mode=new#to=${encodeURIComponent(
                            player
                          )}&subject=${encodeURIComponent(
                            subject
                          )}&message=${encodeURIComponent(
                            getTemplate(template, player)
                          )}`}
                          target="_blank"
                          onClick={(e) => {
                            e.target.style.color = "gray";
                          }}
                        >
                          {player}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                <div className="font-bold">Generator kodów</div>
                <div className="mt-2 flex flex-col">
                  Nazwa gracza
                  <input
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    className=" w-full rounded-md resize"
                  />
                  <button
                    onClick={generate}
                    disabled={!playerName}
                    className={
                      "bg-white text-black p-2 m-2 rounded-xl" +
                      (!playerName ? " bg-gray-200 text-gray-300" : "")
                    }
                  >
                    Generuj
                  </button>
                  <input
                    value={code}
                    disabled
                    className=" w-full rounded-md resize"
                  />
                  <div
                    onClick={() => {
                      navigator.clipboard.writeText(code);
                    }}
                    className={
                      "bg-white text-black p-2 m-2 rounded-xl" +
                      (!code ? " bg-gray-200 text-gray-300" : "")
                    }
                  >
                    Skopiuj do schowka
                  </div>
                  <button
                    onClick={() => setMailingMode(true)}
                    className={"bg-white text-black p-2 m-2 rounded-xl"}
                  >
                    Generuj listę mailingową
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CodeGen;
