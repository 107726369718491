import React from "react";

const AttackerOverview = ({ formattedData, groupedData }) => {
  const [expandedAttacker, setExpandedAttacker] = React.useState(null);

  return (
    <div className="flex flex-col items-center">
      {Object.entries(formattedData)
        .sort(([a, b], [c, d]) => (b.length > d.length ? -1 : 1))
        .map(([attacker, data]) => {
          const groupedData = {};
          data.forEach((item) => {
            const origin = item.origin;
            if (groupedData[origin] instanceof Array) {
              groupedData[origin].push(item);
            } else {
              groupedData[origin] = [item];
            }
          });

          const nobleCount = data.filter((i) => i.isNoble);
          return (
            <div key={attacker} className="flex w-1/2 flex-col">
              <div
                className="flex w-full justify-between"
                onClick={(e) =>
                  setExpandedAttacker(
                    expandedAttacker === attacker ? null : attacker
                  )
                }
              >
                <p>{attacker}</p>
                <div className="flex">
                  {data.length}
                  {nobleCount.length > 0 && (
                    <p className="text-yellow-500">({nobleCount.length})</p>
                  )}
                </div>
              </div>
              <div
                className={
                  "h-0 overflow-hidden" +
                  (expandedAttacker === attacker ? " h-auto" : "")
                }
              >
                {Object.entries(groupedData)
                  .sort(
                    ([aKey, aValue], [bKey, bValue]) =>
                      bValue.length - aValue.length
                  )
                  .map(([origin, items]) => {
                    const noblesNested = items.filter((i) => i.isNoble).length;
                    return (
                      <div key={origin}>
                        {origin} - {items.length}
                        {noblesNested > 0 && (
                          <span className="text-yellow-500">
                            ({noblesNested})
                          </span>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AttackerOverview;
