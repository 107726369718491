import React, { useState } from "react";
import { parseIncomings } from "../helpers/helpers";
import { generateCode } from "./CodeGen";

function AddIncomings({ onClose, data }) {
  const [sorter, setSorter] = useState("incoming");

  const countDef = (units) => {
    let sum = 0;

    Object.entries(units).forEach(([key, value]) => {
      if (key === "spear") {
        sum += value;
      } else if (key === "sword") {
        sum += value;
      } else if (key === "archer") {
        sum += value;
      } else if (key === "heavy") {
        sum += value * 4;
      }
    });

    return sum;
  };
  const countOff = (units) => {
    let sum = 0;

    Object.entries(units).forEach(([key, value]) => {
      if (key === "axe") {
        sum += value;
      } else if (key === "light") {
        sum += value * 4;
      } else if (key === "marcher") {
        sum += value * 5;
      } else if (key === "ram") {
        sum += value * 5;
      }
    });

    return sum;
  };
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center z-10">
        <div
          className="opacity-40 bg-black w-full h-full absolute"
          onClick={onClose}
        />
        {data ? (
          <div className="border-solid border-black bg-gray-300 z-10 self-center text-black p-5 overflow-auto h-1/2 ">
            <div>{data.player_name}</div>
            <div>
              Sortuj:{" "}
              <span onClick={() => setSorter("incoming")}>Incoming</span> |
              <span onClick={() => setSorter("off")}> Off</span> |
              <span onClick={() => setSorter("def")}> Obstawa</span>
            </div>
            <br />
            <div>
              {data.villages
                .sort((a, b) => {
                  switch (sorter) {
                    case "incoming":
                      return b[sorter] - a[sorter];
                    case "off":
                      return countOff(b.units) - countOff(a.units);
                    case "def":
                      return countDef(b.village) - countDef(a.village);
                    default:
                      return b[sorter] - a[sorter];
                  }
                })
                .map((village) => {
                  // console.log(village);
                  const def = countDef(village.units);
                  const off = countOff(village.units);
                  const isDef = def > off;
                  return (
                    <div>
                      {village.village_coords}
                      {isDef ? <span>DEF</span> : <span>OFF</span>}-{" "}
                      <span className="text-red-500">
                        In: {village.incoming}
                      </span>{" "}
                      -
                      <span className="text-green-500">
                        Out: {village.outgoing}
                      </span>
                      <div>
                        W wiosce: {countDef(village.village)} + Własne:
                        <span className={`text-${isDef ? "blue" : "red"}-500`}>
                          {isDef ? def : off}
                        </span>
                      </div>
                      <br />
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          "Poczekaj na załodowanie danych"
        )}
      </div>
    </div>
  );
}

export default AddIncomings;
