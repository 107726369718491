import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React from "react";

const Villages = () => {
  const [villages, setVillages] = React.useState([]);
  const [addOpen, setAddOpen] = React.useState(false);
  const [villageType, setVillageType] = React.useState("");
  const [villageOwner, setVillageOwner] = React.useState("");
  const [villageCoord, setVillageCoord] = React.useState("");
  const [villagePopulationBonus, setVillagePopulationBonus] =
    React.useState("");

  React.useEffect(() => {
    fetch("https://api.trackitup.pl:3000/api/villages", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data[0]);
        setVillages(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const columnDefs = [
    { headerName: "Właściciel", field: "owner.value", filter: true },
    {
      headerName: "Wioska",
      field: "coord",
      filter: true,
    },
    {
      headerName: "Typ wioski",
      field: "villageType.value",
      filter: true,
    },
    {
      headerName: "Off Ubito",
      field: "offKilledAt",
      filter: true,
    },
    {
      headerName: "Off Powrót",
      field: "offReturnAt.value",
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("DD.MM.YYYY HH:mm:ss") : "",
      filter: true,
    },
    {
      headerName: "Bonus populacji",
      field: "populationBonus",
      valueFormatter: (params) => (params.value === true ? "Tak" : "Nie"),
      filter: true,
    },
  ];

  const handleAddVillage = () => {
    fetch("https://api.trackitup.pl:3000/api/villages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        owner: villageOwner,
        coord: villageCoord,
        villageType: villageType,
        populationBonus: villagePopulationBonus,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setVillages([...villages, data]);
        setAddOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <button
        className="bg-white text-black p-2 m-2 w-60 rounded-xl self-center"
        onClick={() => setAddOpen(true)}
      >
        Dodaj Wioskę
      </button>
      <div
        className="ag-theme-alpine"
        style={{ height: "850px", width: "100%" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          enableCellTextSelection={true}
          rowData={villages}
        ></AgGridReact>
      </div>
      {addOpen && (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex justify-center z-10">
            <div
              className="opacity-40 bg-black w-full h-full absolute"
              onClick={() => setAddOpen(false)}
            />
            <div className="border-solid border-black bg-gray-300 z-10 self-center text-black p-5">
              <div className="font-bold">Dodaj Wioskę</div>
              <div className="mt-2 flex flex-col">
                <input
                  type="text"
                  value={villageOwner}
                  onChange={(e) => setVillageOwner(e.target.value)}
                  placeholder="Właściciel"
                  className="w-60 mb-2 rounded-md"
                />
                <input
                  type="text"
                  value={villageCoord}
                  onChange={(e) => setVillageCoord(e.target.value)}
                  placeholder="Współrzędne 123|456"
                  className="w-60 mb-2 rounded-md"
                />
                <select
                  value={villageType}
                  onChange={(e) => setVillageType(e.target.value)}
                  className="w-60 rounded-md"
                >
                  <option value="">Wybierz typ wioski</option>
                  <option value="off">Off</option>
                  <option value="def">Def</option>
                  <option value="church">Church</option>
                </select>
                <label htmlFor="populationBonus">Bonus populacji</label>
                <input
                  type="checkbox"
                  checked={villagePopulationBonus}
                  onChange={(e) => setVillagePopulationBonus(e.target.checked)}
                  className="w-60 rounded-md"
                />
                <button
                  onClick={handleAddVillage}
                  disabled={!villageType || !villageOwner || !villageCoord}
                  className={
                    "bg-white text-black p-2 m-2 rounded-xl" +
                    (!villageType || !villageOwner || !villageCoord
                      ? " bg-gray-200 text-gray-300"
                      : "")
                  }
                >
                  Dodaj
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Villages;
