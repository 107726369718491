import React, { useState } from "react";
import { parseIncomings } from "../helpers/helpers";
import { generateCode } from "./CodeGen";

function AddIncomings({ onClose, isOpen, onAdd, secure = true }) {
  const [player, setPlayer] = useState("");
  const [code, setCode] = useState("");
  const [incomings, setIncomings] = useState("");
  const [message, setMessage] = useState({ type: "", text: "" });
  const [clicked, setClicked] = useState(false);
  const [codeDoesntMatch, setCodeDoesntMatch] = useState(false);

  const checkCode = () => {
    return generateCode(player) === code;
  };

  const addIncomings = (player, incomings) => {
    const chunkSize = 400;
    const chunks = [];
    // console.log(incomings);
    if (incomings?.length === 0)
      return setMessage({
        type: "error",
        text: "Nie udało się odczytać zrzutu",
      });

    setMessage({ type: "Pending", text: "Trwa dodawanie..." });
    for (let i = 0; i < incomings.length; i += chunkSize) {
      chunks.push(incomings.slice(i, i + chunkSize));
    }

    const sendChunk = (chunk, i) => {
      fetch("https://api.trackitup.pl:3000/api/incomings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          playerName: player + (i > 0 ? "_$_part" + i : ""),
          attackArray: chunk,
        }),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Chunk sent successfully");
          } else {
            setMessage({ type: "error", text: "Nie udało się dodać zrzutu" });
            console.error("Failed to send chunk");
          }
        })
        .catch((error) => {
          setMessage({ type: "error", text: "Nie udało się dodać zrzutu" });
          console.error("Error:", error);
        });
    };

    chunks.forEach((chunk, i) => {
      sendChunk(chunk, i);
    });

    if (message.type !== "error") {
      setMessage({ type: "success", text: "Zrzut dodany pomyślnie" });
    }

    return incomings;
  };

  const handleSend = () => {
    const formattedIncomings = parseIncomings(incomings);

    if ((secure && checkCode()) || !secure) {
      setClicked(true);
      const returnValue = addIncomings(player, formattedIncomings);
      // onAdd(formattedIncomings);
      setPlayer("");
      setCode("");
      setIncomings("");
    } else {
      setCodeDoesntMatch(true);
    }
  };

  const getColor = (type) => {
    switch (type) {
      case "error":
        return "bg-red-300";
      case "success":
        return "bg-green-300";
      case "Pending":
        return "bg-yellow-300";
      default:
        return "";
    }
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-10">
          <div
            className="opacity-40 bg-black w-full h-full absolute"
            onClick={() => {
              setMessage({ type: "", text: "" });
              onClose();
            }}
          />
          <div className="border-solid border-black bg-gray-300 z-10 self-center text-black p-5">
            <div className="font-bold">Dodaj zrzut</div>
            <div className="">
              Gracz
              <input
                value={player}
                onChange={(e) => setPlayer(e.target.value)}
                className=" w-full rounded-md"
              ></input>
            </div>
            {secure && (
              <div className="">
                Kod
                <input
                  value={code}
                  onChange={(e) => {
                    setCodeDoesntMatch(false);
                    setCode(e.target.value);
                  }}
                  className=" w-full rounded-md"
                ></input>
                {codeDoesntMatch && (
                  <div className="text-red-500">Kod nie pasuje</div>
                )}
              </div>
            )}
            <div className="mt-2 flex flex-col">
              Zrzut
              <textarea
                value={incomings}
                onChange={(e) => setIncomings(e.target.value)}
                rows="5"
                cols="33"
                className=" w-full rounded-md resize"
              ></textarea>
              <div className={"m-1 p-1 rounded-xl " + getColor(message.type)}>
                {message.text}
              </div>
              <button
                onClick={handleSend}
                disabled={!player || (secure && !code) || !incomings}
                className={
                  "bg-white text-black p-2 m-2 rounded-xl" +
                  (!player || (secure && !code) || !incomings
                    ? " bg-gray-200 text-gray-300"
                    : "")
                }
              >
                {message.type === "error" ? message.text : "Wyślij"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddIncomings;
