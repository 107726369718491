import React from "react";
import { AgGridReact } from "ag-grid-react";

const SummaryExtended = ({ richData }) => {
  const [viewVillages, setViewVillages] = React.useState("");
  const [villages, setVillages] = React.useState([]);
  const [openVillage, setOpenVillage] = React.useState(undefined);
  const [openVillageReports, setOpenVillageReports] = React.useState([]);
  const transformedData = richData.reduce((acc, data) => {
    const target = data.target;
    if (!acc[target]) {
      acc[target] = [];
    }
    acc[target].push(data);
    return acc;
  }, {});

  React.useEffect(() => {
    fetch("https://api.trackitup.pl:3000/api/villages", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setVillages(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const gridData = Object.entries(transformedData).map(([key, value]) => {
    const closes = value.filter((item) => item.isLastSent).length;
    const distincts = value.filter(
      (item) => item.distinctPercent === 100
    ).length;
    const uniquePercent = Math.round((distincts / value.length) * 100);
    const sum = value.length;
    const nobles = value.filter((item) => item.isNoble).length;
    const averageDistincts = Math.round(
      value.reduce((total, item) => total + item.distinctPercent, 0) /
        value.length
    );
    const attackTypes = {
      off: 0,
      def: 0,
    };
    value.forEach((item) => {
      const type = villages.find((village) => village.coord === item.origin)
        ?.villageType?.value;
      if (type === "off") attackTypes.off += 1;
      if (type === "def" || type === "church") attackTypes.def += 1;
    });

    return {
      target: key,
      targetPlayer: value[0].targetPlayer,
      closes,
      closesPercent: Math.round((closes / value.length) * 100),
      distincts,
      uniquePercent,
      averageDistincts,
      sum,
      nobles,
      attackTypes,
    };
  });

  const onGridReady = (params) => {
    // console.log(params.api.getDisplayedRowCount());
  };

  const onCellClick = (context) => {
    setOpenVillage(context?.value);
    if (context?.value)
      fetch("https://api.trackitup.pl:3000/api/reports/" + context.value)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setOpenVillageReports(data);
        });
  };

  const getAttackType = (units) => {
    if (parseInt(units.snob) > 0) {
      return "Gruby";
    }

    let sumOff = parseInt(units.axe);
    sumOff += parseInt(units.light) * 4;
    sumOff += parseInt(units.ram) * 5;

    if (sumOff > 16000) {
      return "Full Off";
    }
    if (sumOff > 1500) {
      return "1/2 Off";
    }
    if (units.catapult > 49) {
      return "Burzak";
    }
    if (sumOff < 1500) {
      return "Fejk";
    }
    return "???";
  };

  const columnDefs = [
    { headerName: "Obrońca", field: "targetPlayer", filter: true },
    {
      headerName: "Wioska",
      field: "target",
      filter: true,
    },
    {
      headerName: "Procent skladany",
      field: "averageDistincts",
      valueFormatter: (params) => `${params.value}%`,
      filter: true,
    },
    {
      headerName: "Procent unikalnych",
      field: "uniquePercent",
      valueFormatter: (params) => `${params.value}%`,
      filter: true,
    },
    {
      headerName: "Unikalne ataki",
      field: "distincts",
      filter: true,
    },
    {
      headerName: "Łącznie ataków",
      field: "sum",
      filter: true,
    },
    {
      headerName: "Zamknięcia",
      field: "closes",
      filter: true,
    },
    {
      headerName: "Zamknięcia %",
      field: "closesPercent",
      valueFormatter: (params) => `${params.value}%`,
      filter: true,
    },
    {
      headerName: "Ataki z offa",
      field: "attackTypes.off",
      filter: true,
    },
    {
      headerName: "Ataki z defa",
      field: "attackTypes.def",
      filter: true,
    },
    {
      headerName: "Grubych",
      field: "nobles",
      filter: true,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: "900px", width: "100%" }}>
      <button
        className="bg-white text-black p-2 m-2 w-60 rounded-xl self-center"
        onClick={() => {
          navigator.clipboard.writeText(viewVillages);
        }}
      >
        Kopiuj Widoczne Coordy
      </button>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={gridData}
        onGridReady={onGridReady}
        enableCellTextSelection={true}
        onCellClicked={onCellClick}
        onFilterChanged={(e) =>
          setViewVillages(
            e.api
              .getRenderedNodes()
              .map((i) => {
                console.log(i.data);
                return i.data.target;
              })
              .join("\n")
          )
        }
      ></AgGridReact>
      {openVillage && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-10">
          <div
            className="opacity-40 bg-black w-full h-full absolute"
            onClick={() => setOpenVillage(undefined)}
          />
          <div className="border-solid border-black bg-gray-300 z-10 self-center text-black p-5 overflow-auto h-1/2 ">
            <div>{openVillage}</div>
            {openVillageReports.map((report) => {
              return (
                <div>
                  {report.date} - {getAttackType(report.attacker.unitsBefore)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryExtended;
