import { AgGridReact } from "ag-grid-react";
import React, { useEffect } from "react";
import { calculateSendTime, calculteDistance } from "../helpers/helpers";
import moment from "moment";

const images = {
  spear:
    "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_spear.png",
  sword:
    "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_sword.png",
  axe: "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_axe.png",
  arch: "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_archer.png",
  spy: "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_spy.png",
  light:
    "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_light.png",
  marcher:
    "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_marcher.png",
  heavy:
    "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_heavy.png",
  ram: "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_ram.png",
  catapult:
    "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_catapult.png",
  knight:
    "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_knight.png",
  snob: "https://dspl.innogamescdn.com/asset/2e8dac1e/graphic/unit/unit_snob.png",
};

const Raports = () => {
  const [rawData, setRawData] = React.useState([]);
  const [openReport, setOpenReport] = React.useState(null);

  const countOffUnits = (units) => {
    let offUnits = parseInt(units.axe);
    offUnits += parseInt(units.light) * 4;
    offUnits += parseInt(units.marcher) * 5;
    offUnits += parseInt(units.ram) * 6;
    return offUnits;
  };

  const columnDefs = [
    {
      headerName: "Wysłanie wojsk",
      filter: true,
      valueGetter: (params) => {
        const distance = calculteDistance(
          params.data.attacker.village,
          params.data.defender.village
        );
        const sent = calculateSendTime(
          params.data.date,
          distance,
          params.data.attacker.unitsBefore?.snob > 0
        );

        return moment(sent).format("DD.MM.YYYY HH:mm:ss");
      },
    },
    { headerName: "Czas dotarcia", field: "date", filter: true },
    { headerName: "Atakujący", field: "attacker.player", filter: true },
    { headerName: "Wioska atakująca", field: "attacker.village", filter: true },
    { headerName: "Obrońca", field: "defender.player", filter: true },
    { headerName: "Wioska broniąca", field: "defender.village", filter: true },
    {
      headerName: "Gruby",
      filter: true,
      field: "attacker.unitsBefore.snob",
    },
    {
      headerName: "Jednostek off",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => {
        if (!params.data.attacker?.unitsBefore) return "???";
        return countOffUnits(params.data.attacker.unitsBefore);
      },
    },
    {
      headerName: "Katapulty",
      filter: true,
      field: "attacker.unitsBefore.catapult",
    },
    {
      headerName: "% ubitych jednostek",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => {
        if (!params.data.attacker?.unitsBefore?.axe) return "???";
        return (
          Math.round(
            (countOffUnits(params.data.attacker?.unitsKilled) /
              countOffUnits(params.data.attacker?.unitsBefore)) *
              100
          ) + "%"
        );
      },
    },
    {
      headerName: "Poparcie po ataku",
      field: "defender.support",
      filter: true,
    },
  ];

  useEffect(() => {
    fetch("https://api.trackitup.pl:3000/api/reports")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRawData(data);
      });
  }, []);

  const onCellClick = (context) => {
    console.log(context.data);
    setOpenReport(context?.data);
  };
  return (
    <div>
      {rawData.length} raportów
      {openReport && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center z-10">
          <div
            className="opacity-40 bg-black w-full h-full absolute"
            onClick={() => setOpenReport(null)}
          />
          <div className="border-solid border-black bg-gray-300 z-10 self-center text-black p-5 overflow-auto ">
            <div>
              <div>
                <div>
                  {openReport.attacker.player} ({openReport.attacker.village})
                  atakuje {openReport.defender.village}
                </div>
                <div className="text-left">Czas bitwy {openReport.date}</div>
                <div>
                  <div className="">
                    <table className="w-full">
                      <tr>
                        <th className="text-left">Agresor:</th>
                        <th className="text-left" colSpan={12}>
                          {openReport.attacker.player}
                        </th>
                      </tr>
                      <tr>
                        <td className="text-left">Pochodzenie:</td>
                        <td className="text-left" colSpan={12}>
                          {openReport.attacker.village}
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        {Object.keys(openReport.attacker?.unitsBefore).map(
                          (unit) => {
                            return (
                              <th className="justify-center">
                                <div className="flex justify-center">
                                  <img
                                    className={
                                      openReport.attacker.unitsBefore[unit] ===
                                      "0"
                                        ? "opacity-40"
                                        : ""
                                    }
                                    src={images[unit]}
                                  />
                                </div>
                              </th>
                            );
                          }
                        )}
                      </tr>
                      <tr>
                        <td className="text-left">Ilość:</td>
                        {Object.values(openReport.attacker?.unitsBefore).map(
                          (count) => {
                            return (
                              <td
                                className={count === "0" ? "text-gray-400" : ""}
                              >
                                {count}
                              </td>
                            );
                          }
                        )}
                      </tr>
                      <tr>
                        <td className="text-left">Straty:</td>
                        {Object.values(openReport.attacker?.unitsKilled).map(
                          (count) => {
                            return (
                              <td
                                className={count === "0" ? "text-gray-400" : ""}
                              >
                                {count}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    </table>
                    {openReport.attacker.faith && (
                      <div className="text-left">
                        Żołnierze walczyli umocnieni swą wiarą. Siła uderzenia:
                        100%
                      </div>
                    )}
                  </div>
                </div>
                <table className="w-full">
                  <tr>
                    <th className="text-left">Obrońca:</th>
                    <th className="text-left" colSpan={12}>
                      {openReport.defender.player}
                    </th>
                  </tr>
                  <tr>
                    <td className="text-left">Cel:</td>
                    <td className="text-left" colSpan={12}>
                      {openReport.defender.village}
                    </td>
                  </tr>
                  {openReport.defender?.unitsBefore?.spear ? (
                    <>
                      <tr>
                        <th></th>
                        {Object.keys(openReport.defender?.unitsBefore).map(
                          (unit) => {
                            return (
                              <th className="justify-center">
                                <div className="flex justify-center">
                                  <img
                                    className={
                                      openReport.defender.unitsBefore[unit] ===
                                      "0"
                                        ? "opacity-40"
                                        : ""
                                    }
                                    src={images[unit]}
                                  />
                                </div>
                              </th>
                            );
                          }
                        )}
                      </tr>
                      <tr>
                        <td className="text-left">Ilość:</td>
                        {Object.values(openReport.defender?.unitsBefore).map(
                          (count) => {
                            return (
                              <td
                                className={count === "0" ? "text-gray-400" : ""}
                              >
                                {count}
                              </td>
                            );
                          }
                        )}
                      </tr>
                      <tr>
                        <td className="text-left">Straty:</td>
                        {Object.values(openReport.defender?.unitsKilled).map(
                          (count) => {
                            return (
                              <td
                                className={count === "0" ? "text-gray-400" : ""}
                              >
                                {count}
                              </td>
                            );
                          }
                        )}
                      </tr>{" "}
                    </>
                  ) : (
                    <tr>Nie wykryto jednostek wroga</tr>
                  )}
                </table>
                {openReport.defender.faith && (
                  <div className="text-left">
                    Żołnierze walczyli umocnieni swą wiarą. Siła uderzenia: 100%
                  </div>
                )}
                {openReport.defender.wallAfter && (
                  <div>
                    Mur uszkodzony z poziomu{" "}
                    <b>{openReport.defender.wallBefore}</b> do poziomu{" "}
                    <b>{openReport.defender.wallAfter}</b>
                  </div>
                )}
                {openReport.defender.support && (
                  <div>Poparcie spadło do {openReport.defender.support}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="ag-theme-alpine"
        style={{ height: "900px", width: "100%" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          enableCellTextSelection={true}
          rowData={rawData}
          onCellClicked={onCellClick}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default Raports;
