export const formatArrivalDate = (dateString) => {
  if (!dateString) return "null";
  if (dateString.startsWith("dzisiaj")) {
    //get todays date and add the hours and minutes from the string
    const today = new Date();
    const hourString = dateString.replace("dzisiaj o ", "");
    const [hours, minutes, seconds, milliseconds] = hourString.split(":");
    today.setHours(hours);
    today.setMinutes(minutes);
    today.setSeconds(seconds);
    today.setMilliseconds(milliseconds);
    return today.getTime();
  } else if (dateString.startsWith("jutro")) {
    //get tomorrows date and add the hours and minutes from the string
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const hourString = dateString.replace("jutro o ", "");
    const [hours, minutes, seconds, milliseconds] = hourString.split(":");
    tomorrow.setHours(hours);
    tomorrow.setMinutes(minutes);
    tomorrow.setSeconds(seconds);
    tomorrow.setMilliseconds(milliseconds);
    return tomorrow.getTime();
  } else {
    const dateStringWithoutPrefix = dateString.replace("dnia ", "");
    let date, hourString;

    if (dateStringWithoutPrefix.includes("o")) {
      [date, hourString] = dateStringWithoutPrefix.split(" o ");
    } else {
      [date, hourString] = dateStringWithoutPrefix.split(" ");
    }
    const [day, month, year] = date.split(".");
    const [hours, minutes, seconds, milliseconds] = hourString.split(":");
    const formattedDate = new Date(
      new Date().getFullYear(),
      month - 1,
      day,
      hours,
      minutes,
      seconds,
      milliseconds || 0
    );
    return formattedDate.getTime();
  }
};
export const formatDateIfNeeded = (date) => {
  // console.log(date);
  let formattedArrival;
  if (date.split) {
    formattedArrival = new Date(formatArrivalDate(date));
  } else {
    formattedArrival = new Date(date);
  }
  return formattedArrival;
};
export const calculteDistance = (origin, target) => {
  const [originX, originY] = origin.split("|");
  const [targetX, targetY] = target.split("|");

  const x = Math.abs(originX - targetX);
  const y = Math.abs(originY - targetY);

  return Math.sqrt(x * x + y * y);
};

export const calculateSendTime = (arrival, distance, isNoble) => {
  const duration = Math.round(distance * (isNoble ? 35 : 30) * 60 * 1000);
  const arrivalAsDate = formatArrivalDate(arrival);
  return arrivalAsDate - duration;
};

export const parseIncomings = (incomings) => {
  const targets = incomings.split("[b]Wioska:[/b]");
  const formattedTargets = [];
  targets.shift();
  targets.map((target) => {
    let targetVillage = target.substring(8, target.indexOf("[/coord]"));

    const attacks = target.split("[command]attack[/command]");

    attacks.shift();
    attacks.forEach((attack) => {
      const titleIndexEnd = attack.indexOf("[coord]");
      const title = attack.substring(0, titleIndexEnd);

      const originIndexEnd = attack.indexOf("[/coord]");
      const origin = attack.substring(titleIndexEnd + 7, originIndexEnd);

      const arrivalIndexEnd = attack.indexOf("[player]");
      const arrival = attack.substring(
        originIndexEnd + 8 + 21,
        arrivalIndexEnd
      );

      const attackerIndexEnd = attack.indexOf("[/player]");
      const attacker = attack.substring(arrivalIndexEnd + 8, attackerIndexEnd);

      const originRegex = new RegExp(
        /origin([^§]*)\([0-9][0-9][0-9]\|[0-9][0-9][0-9]\) K[0-9][0-9]/,
        "g"
      );
      const noOriginTitle = title.replace(originRegex, "");
      const isNoble = noOriginTitle.includes("Szlachcic");
      const distance = calculteDistance(origin, targetVillage);

      const sent = calculateSendTime(arrival, distance, isNoble);
      formattedTargets.push({
        target: targetVillage,
        origin,
        attacker,
        attacker_name: attacker,
        distance,
        arrival,
        isNoble,
        sent,
      });
    });
  });

  return formattedTargets;
};
